// VendorMarkups.js
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import vendorsData from "../data/vendors";
import "../styles/ReviewMarkups.css";
import useMarkups from "../components/useMarkups";
import markupData from "../data/vendorMarkups";
import ReviewMarkupsBase from "../components/ReviewMarkupsBase";

const pre = (c) => "review-markups__" + c;

const VendorMarkups = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isTrue = (param) =>
    searchParams.has(param) &&
    (searchParams.get(param) === "true" || searchParams.get(param) === null);

  const [showPricing, setShowPricing] = useState(isTrue("showPricing"));
  const [showVariance, setShowVariance] = useState(isTrue("showVariance"));
  const [onlyShowCustomMarkups, setOnlyShowCustomMarkups] = useState(
    isTrue("onlyShowCustomMarkups")
  );
  const [sortByDiscount, setSortByDiscount] = useState(
    isTrue("sortByDiscount")
  );

  // Update the query params when the checkbox state changes
  useEffect(() => {
    const params = {};
    if (showPricing) params.showPricing = true;
    if (showVariance) params.showVariance = true;
    if (onlyShowCustomMarkups) params.onlyShowCustomMarkups = true;
    if (sortByDiscount) params.sortByDiscount = true;

    setSearchParams(params);
  }, [
    showPricing,
    showVariance,
    onlyShowCustomMarkups,
    sortByDiscount,
    setSearchParams,
  ]);

  const { calcEndUserMarkup, calcDealerMarkup, calcWholesalerMarkup } =
    useMarkups(markupData);

  // Sort and filter vendors based on the checkbox states
  const vendors = (
    sortByDiscount
      ? [...vendorsData].sort((a, b) => {
          const discountA = a.Discount ?? a.AvgDiscount ?? 99;
          const discountB = b.Discount ?? b.AvgDiscount ?? 99;
          return discountA === discountB
            ? a.Vendor.localeCompare(b.Vendor)
            : discountA - discountB;
        })
      : vendorsData
  ).filter((v) => !onlyShowCustomMarkups || v.HasCustomMarkups);

  const columns = [
    "Vendor",
    "Discount",
    "EndUser",
    "Dealer",
    "Wholesaler",
    // "Min Order",
  ];

  // Helper function to get either the markup/variance or the price/variance
  const getMarkupOrPrice = (
    tier,
    discount,
    vendorMarkup,
    standardMarkup,
    showVariance,
    showPricing
  ) => {
    if (!discount) {
      return showVariance ? <i>n/a</i> : standardMarkup.toFixed(2);
    }

    const ListPrice = 100; // Fixed price for the item
    const cost = ListPrice * (1 - discount); // Discounted cost
    const vendorPrice = cost * vendorMarkup; // Final price with vendor markup
    const standardPrice = cost * standardMarkup;

    if (showPricing) {
      if (showVariance) {
        const priceDifference = standardPrice - vendorPrice;
        const className =
          priceDifference > 0
            ? pre("variance-positive")
            : priceDifference < 0
            ? pre("variance-negative")
            : "";
        return <span className={className}>{priceDifference.toFixed(2)}</span>;
      }
      return standardPrice.toFixed(2); // Show the vendor's calculated price
    }

    if (showVariance) {
      const variance = standardMarkup - vendorMarkup;
      const className =
        variance > 0
          ? pre("variance-positive")
          : variance < 0
          ? pre("variance-negative")
          : "";
      return <span className={className}>{variance.toFixed(2)}</span>;
    }

    return standardMarkup.toFixed(2); // Show the vendor markup if no pricing/variance
  };

  // Render Vendor Row
  const renderVendorRow = (vendor) => {
    const discount = vendor.Discount ?? vendor.AvgDiscount;
    const discountDefined = discount != null;
    const standardEndUserMarkup = discountDefined
      ? calcEndUserMarkup(discount)
      : 1;
    const standardDealerMarkup = discountDefined
      ? calcDealerMarkup(discount)
      : 1;
    const standardWholesalerMarkup = discountDefined
      ? calcWholesalerMarkup(discount)
      : 1;

    return (
      <div
        className={
          pre("row") + (vendor.HasCustomMarkups ? " custom-markup" : "")
        }
        key={vendor.Vendor}
      >
        <div
          style={{ fontStyle: vendor.HasCustomMarkups ? "italic" : "normal" }}
        >
          {vendor.Vendor}
        </div>
        <div>
          {vendor.AvgDiscount ? <span>&asymp;</span> : ""}
          {(discount * 100).toFixed(2)}%
        </div>
        <div>
          {getMarkupOrPrice(
            "endUser",
            discount,
            vendor.EnduserMarkup,
            standardEndUserMarkup,
            showVariance,
            showPricing
          )}
        </div>
        <div>
          {getMarkupOrPrice(
            "dealer",
            discount,
            vendor.DealerMarkup,
            standardDealerMarkup,
            showVariance,
            showPricing
          )}
        </div>
        <div>
          {getMarkupOrPrice(
            "wholesaler",
            discount,
            vendor.WholesalerMarkup,
            standardWholesalerMarkup,
            showVariance,
            showPricing
          )}
        </div>
        {/* <div>{vendor.MinOrder}</div> */}
      </div>
    );
  };

  const checkboxes = [
    {
      label: " Show pricing for $100 item",
      checked: showPricing,
      onChange: (e) => setShowPricing(e.target.checked),
    },
    {
      label: " Show variance from old pricing",
      checked: showVariance,
      onChange: (e) => setShowVariance(e.target.checked),
    },
    {
      label:
        " Just vendors with custom markups (" +
        vendors.filter((vendor) => vendor.HasCustomMarkups).length +
        ")",
      checked: onlyShowCustomMarkups,
      onChange: (e) => setOnlyShowCustomMarkups(e.target.checked),
    },
    {
      label: " Sort by Discount",
      checked: sortByDiscount,
      onChange: (e) => setSortByDiscount(e.target.checked),
    },
  ];

  const handleContainerDoubleClick = () => {
    setShowPricing((prev) => !prev);
  };

  return (
    <ReviewMarkupsBase
      title="Review Vendor Markups"
      returnUrl="/"
      data={vendors}
      columns={columns}
      renderVendorRow={renderVendorRow}
      checkboxes={checkboxes}
      containerClass="vendors"
      onContainerDoubleClick={handleContainerDoubleClick}
    />
  );
};

export default VendorMarkups;
