import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PartPricing from "./PartPricing";
import PartPricing2 from "./PartPricing2";
import VendorMarkups from "./VendorMarkups";
import VendorMarkups2 from "./VendorMarkups2";
import PartstownMarkups from "./PartstownMarkups";
import PricingTable from "./PricingTable";
import Invite from "../auth/Invite";
import Unauthorized from "../auth/Unauthorized";
import withAuth from "../auth/withAuth"; // Import your HOC
import "../styles/styles.css";
import "../styles/auth.css";

// Wrap components with withAuth
const ProtectedPartPricing = withAuth(PartPricing);
const ProtectedPartPricing2 = withAuth(PartPricing2);
const ProtectedVendorMarkups = withAuth(VendorMarkups);
const ProtectedVendorMarkups2 = withAuth(VendorMarkups2);
const ProtectedPartstownMarkups = withAuth(PartstownMarkups);
const ProtectedPricingTable = withAuth(PricingTable);

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/invite" element={<Invite />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route
            path="/partstown-markups"
            element={<ProtectedPartstownMarkups />}
          />
          <Route path="/vendor-markups" element={<ProtectedVendorMarkups />} />
          <Route
            path="/vendor-markups2"
            element={<ProtectedVendorMarkups2 />}
          />
          <Route path="/pricing" element={<ProtectedPricingTable />} />
          <Route path="/" element={<ProtectedPartPricing2 />} />
          <Route path="/old" element={<ProtectedPartPricing />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
