import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SelectManufacturer from "../components/SelectManufacturer";
import SelectPartstownDiscount from "../components/SelectPartstownDiscount";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../styles/react-tabs.css";
import "../styles/PartPricingStyles.css";

const ReviewMarkupsLink = ({ to, label }) => {
  if (!label) label = "Review Markups";
  return (
    <Link className="review-markups-link" to={to}>
      {label}
    </Link>
  );
};

const PartPricing = () => {
  const [searchString, setSearchString] = useState("");
  const [tier, setTier] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pt = params.get("pt");
    const search = params.get("make");
    const tier = params.get("tier");

    setTabIndex(pt ? 1 : 0);
    if (search) {
      setSearchString(search);
    }
    if (tier) {
      setTier(tier);
    }
  }, [location.search]);

  const pre = (c) => "part-pricing__" + c;

  const handleTabSelect = (index) => {
    setTabIndex(index);
    const params = new URLSearchParams(location.search);

    if (index === 1) {
      params.set("pt", "true");
    } else {
      params.delete("pt");
    }

    const newSearch = params.toString();
    navigate({
      pathname: location.pathname,
      search: newSearch ? `?${newSearch}` : "",
    });
  };

  return (
    <div className={pre("container")}>
      {/* <div className="alert">
        <strong>ATTENTION:</strong>&ensp;Case Parts internal use only!
      </div> */}
      <div className={pre("pageHeader")}>
        <h1>
          Special Order Pricing Calculator
          <span>
            <Link to={`/${location.search}`}>try new UI</Link>
          </span>
        </h1>
      </div>

      <Tabs
        selectedIndex={tabIndex}
        onSelect={handleTabSelect}
        className={pre("tab-control")}
      >
        <TabList className={pre("tablist")}>
          <Tab>Case Parts Vendor</Tab>
          <Tab>Parts Town</Tab>
        </TabList>

        <TabPanel className={pre("tabpanel")}>
          <ReviewMarkupsLink to="/pricing" label="Pricing Table" />
          <ReviewMarkupsLink to="/vendor-markups" />
          <SelectManufacturer search={searchString} tier={tier} />
        </TabPanel>
        <TabPanel className={pre("tabpanel")}>
          <ReviewMarkupsLink to="/pricing?pt=true" label="Pricing Table" />
          <ReviewMarkupsLink to="/partstown-markups" />
          <SelectPartstownDiscount search={searchString} tier={tier} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default PartPricing;
