// SelectPartstownDiscount.jsx
import React, { useState, useRef, useEffect } from "react";
import vendors from "../data/partstown.json";
import DisplayPrices from "./DisplayPrices";
import VendorSelect from "./VendorSelect";
import Drawer from "./Drawer";
import ListPriceModal from "./ListPriceModal";
import useMarkups from "./useMarkups";
import markups from "../data/partstownMarkups";

const SelectPartstownDiscount = ({ search, tier }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [cost, setCost] = useState("");
  const [overridePrice, setOverridePrice] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const { calculatePrices } = useMarkups(markups);
  const costInputRef = useRef(null);

  useEffect(() => {
    const show = JSON.parse(
      localStorage.getItem("CPC.spo-pricing-details") || "false"
    );
    setShowDetails(show);
  }, []);

  const handleToggleDetails = () => {
    const newShowDetails = !showDetails;
    localStorage.setItem(
      "CPC.spo-pricing-details",
      JSON.stringify(newShowDetails)
    );
    setShowDetails(newShowDetails);
  };

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption || null);
    setCost("");
    setOverridePrice(null);

    if (selectedOption?.value.Discount) {
      setTimeout(() => costInputRef.current?.focus(), 0);
    }
  };

  const handleCostChange = (event) => {
    setCost(event.target.value);
  };

  const handleListPriceSubmit = (price) => {
    setOverridePrice(price > cost ? price : null);
  };

  const vendorDiscount = selectedVendor?.value?.Discount || 0;
  const actualDiscount = overridePrice
    ? 1 - cost / overridePrice
    : vendorDiscount;

  const prices = calculatePrices(cost, actualDiscount);

  const formatNumber = (value) => {
    const number = parseFloat(value);
    return !number || isNaN(number) ? "0.00" : number.toFixed(2);
  };

  const pre = (c) => `part-pricing__${c}`;

  return (
    <div className={pre("section")}>
      <h1>Select Partstown Line</h1>
      <VendorSelect
        vendors={vendors}
        onChange={handleVendorChange}
        search={search}
      />

      {selectedVendor && (
        <div className={pre("input")}>
          <label>Cost</label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              className={pre("csr-input")}
              type="number"
              value={cost}
              onChange={handleCostChange}
              placeholder="Enter cost"
              ref={costInputRef}
            />
            {cost && !isNaN(cost) && (
              <span
                className={`${pre("calculated")} ${
                  overridePrice ? "overridePrice" : ""
                }`}
                onClick={() => setModalOpen(true)}
              >
                <span>List Price:&nbsp;</span>$
                {formatNumber(overridePrice || prices.listPrice)}
              </span>
            )}
          </div>

          {cost && <DisplayPrices prices={prices} tier={tier} />}

          <Drawer isOpen={showDetails} setIsOpen={handleToggleDetails}>
            <div className={pre("pricing-details")}>
              <h3>Case Parts Pricing Details</h3>
              <div>
                <label>Manufacturer:</label>
                <p>{selectedVendor.label}</p>
              </div>
              <div>
                <label>Discount:</label>
                <p>{(vendorDiscount * 100).toFixed(2)}%</p>
              </div>
              {actualDiscount !== vendorDiscount && (
                <div className="overridePrice">
                  <label>Actual Discount:</label>
                  <p>{(actualDiscount * 100).toFixed(2)}%</p>
                </div>
              )}
              <div>
                <label>End User Markup:</label>
                <p>{formatNumber(1 / (1 - actualDiscount))}</p>
              </div>
              <div>
                <label>Dealer Markup:</label>
                <p>{formatNumber(prices.dealerMarkup)}</p>
              </div>
              <div>
                <label>Wholesaler Markup:</label>
                <p>{formatNumber(prices.wholesalerMarkup)}</p>
              </div>
            </div>
          </Drawer>

          <ListPriceModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            onSubmit={handleListPriceSubmit}
            initialPrice={overridePrice}
          />
        </div>
      )}
    </div>
  );
};

export default SelectPartstownDiscount;
