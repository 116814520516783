import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import DisplayPrices from "./DisplayPrices";
import Drawer from "./Drawer";
import ListPriceModal from "./ListPriceModal";
import useMarkups from "./useMarkups";
import markups from "../data/partstownMarkups";
import toast from "react-hot-toast";

const SelectPartstownDiscount = ({ vendor, tier, isManager }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [cost, setCost] = useState("");
  const [listPrice, setListPrice] = useState("");
  const [isEnteringCost, setIsEnteringCost] = useState(true);
  const [overridePrice, setOverridePrice] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    calculatePrices,
    calcEndUserMarkup,
    calcDealerMarkup,
    calcWholesalerMarkup,
  } = useMarkups(markups);
  const costInputRef = useRef(null);
  const listPriceInputRef = useRef(null);

  useEffect(() => {
    if (vendor) {
      setCost("");
      setListPrice("");
      setOverridePrice(null);
      setTimeout(() => {
        if (isEnteringCost) {
          costInputRef.current?.focus();
        } else {
          listPriceInputRef.current?.focus();
        }
      }, 0);
    }
  }, [vendor, isEnteringCost]);

  const handleToggleDetails = () => {
    const newShowDetails = !showDetails;
    localStorage.setItem(
      "CPC.spo-pricing-details",
      JSON.stringify(newShowDetails)
    );
    setShowDetails(newShowDetails);
  };

  const handleCostChange = (event) => {
    const costValue = Number(event.target.value);
    setCost(costValue);
    if (costValue > 0 && vendor?.Discount) {
      const calculatedListPrice = costValue / (1 - vendor.Discount);
      setListPrice(calculatedListPrice.toFixed(2));
    } else {
      setListPrice("");
    }
  };

  const handleListPriceChange = (event) => {
    const listPriceValue = Number(event.target.value);
    setListPrice(listPriceValue);
    if (listPriceValue > 0 && vendor?.Discount) {
      const calculatedCost = listPriceValue * (1 - vendor.Discount);
      setCost(calculatedCost.toFixed(2));
    } else {
      setCost("");
    }
  };

  const handleListPriceSubmit = (price) => {
    setOverridePrice(price > cost ? price : null);
  };

  const numericCost = parseFloat(cost) || 0;
  const numericListPrice = parseFloat(listPrice) || 0;
  const numericOverridePrice = parseFloat(overridePrice);

  const vendorDiscount = vendor?.Discount || 0;

  let actualDiscount = vendorDiscount;
  if (
    isEnteringCost &&
    !isNaN(numericOverridePrice) &&
    numericOverridePrice !== 0
  ) {
    actualDiscount = 1 - numericCost / numericOverridePrice;
  } else if (!isEnteringCost && numericListPrice > 0) {
    actualDiscount = 1 - numericCost / numericListPrice;
  }

  let prices = null;
  if ((numericCost > 0 || numericListPrice > 0) && !isNaN(actualDiscount)) {
    prices = calculatePrices(numericCost || numericListPrice, actualDiscount);
  }

  const formatNumber = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? "0.00" : number.toFixed(2);
  };

  const pre = (c) => `part-pricing__${c}`;

  const handleCalculatedClick = () => {
    if (isEnteringCost) {
      // Open modal to enter actual list price
      setModalOpen(true);
    } else {
      // Copy calculated cost to clipboard
      const formattedCost = formatNumber(numericCost);
      navigator.clipboard.writeText(formattedCost);
      toast.success(`Copied $${formattedCost}`, {
        position: "bottom-center",
        icon: "📄",
        style: { backgroundColor: "var(--neutral-6)", color: "white" },
      });
    }
  };

  return (
    <div className={pre("section")}>
      {vendor ? (
        <>
          <div className={pre("input")}>
            {isEnteringCost ? (
              <>
                <label>
                  Cost:
                  <Link
                    to="#"
                    onClick={() => setIsEnteringCost(false)}
                    className={pre("input-toggle")}
                  >
                    Enter List Price instead
                  </Link>
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    className={pre("csr-input")}
                    type="number"
                    value={cost}
                    onChange={handleCostChange}
                    placeholder="Enter cost"
                    ref={costInputRef}
                  />
                  {(numericOverridePrice > 0 || numericListPrice > 0) && (
                    <span
                      className={`${pre("calculated")} ${
                        overridePrice ? "overridePrice" : ""
                      }`}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={handleCalculatedClick}
                    >
                      List Price: $
                      {formatNumber(overridePrice || numericListPrice)}
                    </span>
                  )}
                </div>
              </>
            ) : (
              <>
                <label>
                  List Price:
                  <Link
                    to="#"
                    onClick={() => setIsEnteringCost(true)}
                    className={pre("input-toggle")}
                  >
                    Enter Cost instead
                  </Link>
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    className={pre("csr-input")}
                    type="number"
                    value={listPrice}
                    onChange={handleListPriceChange}
                    placeholder="Enter list price"
                    ref={listPriceInputRef}
                  />
                  {numericCost > 0 && (
                    <span
                      className={`${pre("calculated")}`}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={handleCalculatedClick}
                    >
                      Cost: ${formatNumber(numericCost)}
                    </span>
                  )}
                </div>
              </>
            )}

            {prices && <DisplayPrices prices={prices} tier={tier} />}

            {isManager && (
              <Drawer isOpen={showDetails} setIsOpen={handleToggleDetails}>
                <div className={pre("pricing-details")}>
                  <h3>Pricing based on standard markups</h3>
                  <div>
                    <label>Manufacturer:</label>
                    <p>{vendor.Vendor}</p>
                  </div>
                  <div>
                    <label>Discount:</label>
                    <p>{(vendorDiscount * 100).toFixed(2)}%</p>
                  </div>
                  {actualDiscount !== vendorDiscount && (
                    <div className="overridePrice">
                      <label>Actual Discount:</label>
                      <p>{(actualDiscount * 100).toFixed(2)}%</p>
                    </div>
                  )}
                  <div>
                    <label>End User Markup:</label>
                    <p>{formatNumber(calcEndUserMarkup(actualDiscount))}</p>
                  </div>
                  <div>
                    <label>Dealer Markup:</label>
                    <p>{formatNumber(calcDealerMarkup(actualDiscount))}</p>
                  </div>
                  <div>
                    <label>Wholesaler Markup:</label>
                    <p>{formatNumber(calcWholesalerMarkup(actualDiscount))}</p>
                  </div>
                </div>
                <div className="nerdy-details">
                  <div>
                    <Link to="/pricing?pt" className="review-markups-link">
                      Pricing Table
                    </Link>
                  </div>
                </div>
              </Drawer>
            )}

            <ListPriceModal
              isOpen={isModalOpen}
              onClose={() => setModalOpen(false)}
              onSubmit={handleListPriceSubmit}
              initialPrice={overridePrice}
            />
          </div>
        </>
      ) : (
        <h1>Please select a vendor to proceed</h1>
      )}
    </div>
  );
};

export default SelectPartstownDiscount;
