// SelectManufacturer.js
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import DisplayPrices from "./DisplayPrices.jsx";
import Drawer from "./Drawer.jsx";
import ListPriceModal from "./ListPriceModal";
import toast from "react-hot-toast";
import useMarkups from "./useMarkups.jsx";
import markupData from "../data/vendorMarkups.js";

const SelectManufacturer = ({ vendor, tier, isManager }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [listPrice, setListPrice] = useState("");
  const [cost, setCost] = useState("");
  const [overrideListPrice, setOverrideListPrice] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showOldPricing, setShowOldPricing] = useState(true);
  const listPriceInputRef = useRef(null);
  const costInputRef = useRef(null);
  const {
    calcEndUserMarkup,
    calcDealerMarkup,
    calcWholesalerMarkup,
    calcEndUserPrice,
    calcDealerPrice,
    calcWholesalerPrice,
  } = useMarkups(markupData);

  /* For CSR use, they always need to click to see details */
  // useEffect(() => {
  //   const storedDetails = JSON.parse(
  //     localStorage.getItem("CPC.spo-pricing-details") || "false"
  //   );
  //   setShowDetails(storedDetails);
  // }, []);

  useEffect(() => {
    resetPricingState();
    setShowOldPricing(!!vendor?.HasCustomMarkups);
    focusCorrectInput();
  }, [vendor]);

  const resetPricingState = () => {
    setListPrice("");
    setCost("");
    setOverrideListPrice(null);
  };

  const focusCorrectInput = () => {
    const inputRef = vendor?.Discount ? listPriceInputRef : costInputRef;
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleToggleDetails = () => {
    const newShowDetails = !showDetails;
    localStorage.setItem(
      "CPC.spo-pricing-details",
      JSON.stringify(newShowDetails)
    );
    setShowDetails(newShowDetails);
  };

  const handleListPriceChange = (event) => setListPrice(event.target.value);
  const handleCostChange = (event) => setCost(event.target.value);

  const handleListPriceSubmit = (price) => {
    setOverrideListPrice(price > cost ? price : null);
  };

  const calculateCost = () => {
    if (!listPrice || !vendor?.Discount) return null;
    return parseFloat(listPrice) * (1 - vendor.Discount);
  };

  useEffect(() => {
    if (vendor?.Discount && listPrice) {
      setCost(calculateCost());
    }
  }, [vendor, listPrice]);

  const getCalculatedListPrice = () => {
    if (vendor?.Discount) return null;
    return (
      overrideListPrice ||
      (cost && vendor?.AvgDiscount
        ? parseFloat(cost) / (1 - vendor.AvgDiscount)
        : null)
    );
  };

  const getActualAvgDiscount = () => {
    if (!vendor?.Discount) {
      return overrideListPrice
        ? 1 - cost / overrideListPrice
        : vendor?.AvgDiscount;
    }
    return vendor?.Discount;
  };

  const getPrices = () => {
    const effectiveListPrice =
      listPrice || overrideListPrice || calculatedListPrice;
    const effectiveDiscount = vendor?.Discount || vendor?.AvgDiscount;

    return showOldPricing
      ? {
          endUserPrice: (calculatedCost * vendor.EnduserMarkup).toFixed(2),
          dealerPrice: (calculatedCost * vendor.DealerMarkup).toFixed(2),
          wholesalerPrice: (calculatedCost * vendor.WholesalerMarkup).toFixed(
            2
          ),
          dealerMarkup: vendor.DealerMarkup,
          wholesalerMarkup: vendor.WholesalerMarkup,
        }
      : {
          endUserPrice: calcEndUserPrice(effectiveListPrice, effectiveDiscount),
          dealerPrice: calcDealerPrice(effectiveListPrice, effectiveDiscount),
          wholesalerPrice: calcWholesalerPrice(
            effectiveListPrice,
            effectiveDiscount
          ),
          dealerMarkup: calcDealerMarkup(effectiveListPrice, effectiveDiscount),
          wholesalerMarkup: calcWholesalerMarkup(
            effectiveListPrice,
            effectiveDiscount
          ),
        };
  };

  const calculatedCost = vendor?.Discount ? calculateCost() : parseFloat(cost);
  const calculatedListPrice = getCalculatedListPrice();
  const actualAvgDiscount = getActualAvgDiscount();
  const prices = calculatedCost ? getPrices() : null;

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    toast.success(`Copied $${value}`, {
      position: "bottom-center",
      icon: "📄",
      style: { backgroundColor: "var(--neutral-6)", color: "white" },
    });
  };

  const renderInputFields = () => {
    return vendor.Discount ? renderListPriceInput() : renderCostInput();
  };

  const renderListPriceInput = () => (
    <>
      <label>List Price</label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          className={pre("csr-input")}
          type="number"
          value={listPrice}
          onChange={handleListPriceChange}
          placeholder="Enter list price"
          ref={listPriceInputRef}
        />
        {calculatedCost && !isNaN(calculatedCost) && (
          <span
            className={pre("calculated")}
            onClick={() => handleCopy(calculatedCost.toFixed(2))}
          >
            <span>Cost:&nbsp;</span>${calculatedCost.toFixed(2)}
          </span>
        )}
      </div>
    </>
  );

  const renderCostInput = () => (
    <>
      <label>Cost:</label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          className={pre("csr-input")}
          type="number"
          value={cost}
          onChange={handleCostChange}
          placeholder="Enter cost"
          ref={costInputRef}
        />
        {cost && !isNaN(cost) && (
          <span
            className={`${pre("calculated")} ${
              overrideListPrice ? "overridePrice" : ""
            }`}
            onClick={() => setModalOpen(true)}
          >
            <span>List Price:&nbsp;</span>$
            {formatNumber(overrideListPrice || calculatedListPrice)}
          </span>
        )}
      </div>
    </>
  );

  const pre = (c) => `part-pricing__${c}`;
  const formatNumber = (value) =>
    !value || isNaN(value) ? "0.00" : parseFloat(value).toFixed(2);

  return (
    <div className={pre("section")}>
      {vendor ? (
        <>
          <div className={pre("input")}>
            {renderInputFields()}
            {vendor.MinOrder && (
              <div className={pre("minOrder")}>
                Minimum Order: {vendor.MinOrder}
              </div>
            )}
            {prices && <DisplayPrices prices={prices} tier={tier} />}

            {isManager && (
              <Drawer isOpen={showDetails} setIsOpen={handleToggleDetails}>
                <PricingDetails
                  vendor={vendor}
                  showOldPricing={showOldPricing}
                  actualAvgDiscount={actualAvgDiscount}
                  calcEndUserMarkup={calcEndUserMarkup}
                  calcDealerMarkup={calcDealerMarkup}
                  calcWholesalerMarkup={calcWholesalerMarkup}
                />
                <div className="nerdy-details">
                  <label>
                    <input
                      type="checkbox"
                      checked={showOldPricing}
                      onChange={(e) => setShowOldPricing(e.target.checked)}
                    />{" "}
                    Apply custom markups
                  </label>
                  <div>
                    <Link to="/pricing" className="review-markups-link">
                      Pricing Table
                    </Link>
                    <br />
                    <Link to="/vendor-markups" className="review-markups-link">
                      View Vendor Markups
                    </Link>
                    <br />
                    <Link to="/vendor-markups2" className="review-markups-link">
                      Export Vendor Markups
                    </Link>
                  </div>
                </div>
              </Drawer>
            )}

            <ListPriceModal
              isOpen={isModalOpen}
              onClose={() => setModalOpen(false)}
              onSubmit={handleListPriceSubmit}
              initialPrice={overrideListPrice}
            />
          </div>
        </>
      ) : (
        <h1>Please select a vendor to proceed</h1>
      )}
    </div>
  );
};

const PricingDetails = ({
  vendor,
  showOldPricing,
  actualAvgDiscount,
  calcEndUserMarkup,
  calcDealerMarkup,
  calcWholesalerMarkup,
}) => {
  const effectiveDiscount = vendor?.Discount || vendor?.AvgDiscount;

  return (
    <div className="part-pricing__pricing-details">
      <h3>Pricing based on {showOldPricing ? "custom" : "standard"} markups</h3>
      <PricingDetail label="Manufacturer" value={vendor.Vendor} />
      {vendor.Discount ? (
        <PricingDetail
          label="Discount"
          value={`${(vendor.Discount * 100).toFixed(2)}%`}
        />
      ) : (
        <>
          {vendor.AvgDiscount && (
            <PricingDetail
              label="Average Discount"
              value={`≈${(vendor.AvgDiscount * 100).toFixed(2)}%`}
            />
          )}
          {actualAvgDiscount !== vendor.AvgDiscount && (
            <PricingDetail
              label="Actual Discount"
              value={`${(actualAvgDiscount * 100).toFixed(2)}%`}
            />
          )}
        </>
      )}
      <PricingDetail
        label="End User Markup"
        value={
          showOldPricing
            ? vendor.EnduserMarkup.toFixed(2)
            : calcEndUserMarkup(effectiveDiscount).toFixed(2)
        }
      />
      <PricingDetail
        label="Dealer Markup"
        value={
          showOldPricing
            ? vendor.DealerMarkup.toFixed(2)
            : calcDealerMarkup(effectiveDiscount).toFixed(2)
        }
      />
      <PricingDetail
        label="Wholesaler Markup"
        value={
          showOldPricing
            ? vendor.WholesalerMarkup.toFixed(2)
            : calcWholesalerMarkup(effectiveDiscount).toFixed(2)
        }
      />
    </div>
  );
};

const PricingDetail = ({ label, value }) => (
  <div>
    <label>{label}:</label>
    <p>{value}</p>
  </div>
);

export default SelectManufacturer;
