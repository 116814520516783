// SelectManufacturer.jsx
import React, { useState, useRef, useEffect } from "react";
import vendors from "../data/vendors.js";
import DisplayPrices from "./DisplayPrices.jsx";
import VendorSelect from "./VendorSelect.jsx";
import Drawer from "./Drawer.jsx";
import ListPriceModal from "./ListPriceModal";
import toast, { Toaster } from "react-hot-toast";
import useMarkups from "./useMarkups.jsx";
import markupData from "../data/vendorMarkups.js";

const SelectManufacturer = ({ search, tier }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [listPrice, setListPrice] = useState("");
  const [cost, setCost] = useState("");
  const [overrideListPrice, setOverrideListPrice] = useState(null); // Added state for overriding List Price
  const [isModalOpen, setModalOpen] = useState(false); // State to control the ListPriceModal
  const [showOldPricing, setShowOldPricing] = useState(true);
  const listPriceInputRef = useRef(null);
  const costInputRef = useRef(null);
  const {
    calcEndUserMarkup,
    calcDealerMarkup,
    calcWholesalerMarkup,
    calcEndUserPrice,
    calcDealerPrice,
    calcWholesalerPrice,
  } = useMarkups(markupData);

  useEffect(() => {
    const show = JSON.parse(
      localStorage.getItem("CPC.spo-pricing-details") || "false"
    );
    setShowDetails(show);
  }, []);

  const handleToggleDetails = () => {
    const newShowDetails = !showDetails;
    localStorage.setItem(
      "CPC.spo-pricing-details",
      JSON.stringify(newShowDetails)
    );
    setShowDetails(newShowDetails);
  };

  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption || null);
    setListPrice("");
    setCost("");
    setOverrideListPrice(null); // Reset override when vendor changes

    if (selectedOption?.value.Discount) {
      setTimeout(() => listPriceInputRef.current?.focus(), 0);
    } else {
      setTimeout(() => costInputRef.current?.focus(), 0);
    }
  };

  const handleListPriceChange = (event) => {
    setListPrice(event.target.value);
  };

  const handleCostChange = (event) => {
    setCost(event.target.value);
  };

  // Function to handle List Price override submission
  const handleListPriceSubmit = (price) => {
    if (price === 0 || price <= cost) {
      setOverrideListPrice(null); // Clear the override and revert to calculated List Price
    } else {
      setOverrideListPrice(price); // Set the override List Price
    }
  };

  // Calculate cost for vendors with Discount
  const calculateCost = () => {
    if (!listPrice || !selectedVendor?.value.Discount) return null;
    const discount = selectedVendor.value.Discount;
    return parseFloat(listPrice) * (1 - discount);
  };

  useEffect(() => {
    if (selectedVendor?.value.Discount && listPrice) {
      setCost(calculateCost());
    }
  }, [selectedVendor, listPrice]);

  const pre = (c) => `part-pricing__${c}`;

  // For vendors with Discount
  const calculatedCost = selectedVendor?.value.Discount
    ? calculateCost()
    : parseFloat(cost);

  // For vendors without Discount, calculate List Price
  const calculatedListPrice = !selectedVendor?.value.Discount
    ? overrideListPrice ||
      (cost && selectedVendor?.value.AvgDiscount
        ? parseFloat(cost) / (1 - selectedVendor.value.AvgDiscount)
        : null)
    : null;

  // For vendors without Discount, calculate actual AvgDiscount
  const actualAvgDiscount = !selectedVendor?.value.Discount
    ? overrideListPrice
      ? 1 - cost / overrideListPrice
      : selectedVendor?.value.AvgDiscount
    : selectedVendor?.value.Discount;

  // Calculate End User Markup based on actual discount
  const endUserMarkup = actualAvgDiscount ? 1 / (1 - actualAvgDiscount) : null;
  const effectiveListPrice =
    listPrice || overrideListPrice || calculatedListPrice;
  const effectiveDiscount =
    selectedVendor?.value.Discount || selectedVendor?.value.AvgDiscount;
  console.log("List", effectiveListPrice, effectiveDiscount);

  // Calculate prices
  const prices = calculatedCost
    ? showOldPricing
      ? {
          endUserPrice: (
            calculatedCost * selectedVendor.value.EnduserMarkup
          ).toFixed(2),
          dealerPrice: (
            calculatedCost * selectedVendor.value.DealerMarkup
          ).toFixed(2),
          wholesalerPrice: (
            calculatedCost * selectedVendor.value.WholesalerMarkup
          ).toFixed(2),
          dealerMarkup: selectedVendor.value.DealerMarkup,
          wholesalerMarkup: selectedVendor.value.WholesalerMarkup,
        }
      : {
          endUserPrice: calcEndUserPrice(effectiveListPrice, effectiveDiscount),
          dealerPrice: calcDealerPrice(effectiveListPrice, effectiveDiscount),
          wholesalerPrice: calcWholesalerPrice(
            effectiveListPrice,
            effectiveDiscount
          ),
          dealerMarkup: calcDealerMarkup(effectiveListPrice, effectiveDiscount),
          wholesalerMarkup: calcWholesalerMarkup(
            effectiveListPrice,
            effectiveDiscount
          ),
        }
    : null;

  console.log(
    "ListPrice",
    effectiveListPrice,
    "Discount",
    effectiveDiscount,
    prices
  );

  const formatNumber = (value) => {
    const number = parseFloat(value);
    return !number || isNaN(number) ? "0.00" : number.toFixed(2);
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    toast.success(`Copied $${value}`, {
      position: "bottom-center",
      icon: "📄",
      style: { backgroundColor: "var(--neutral-6)", color: "white" },
    });
  };

  return (
    <div className={pre("section")}>
      <h1>Select Vendor</h1>
      <VendorSelect
        vendors={vendors}
        onChange={handleVendorChange}
        search={search}
      />

      {selectedVendor && (
        <div className={pre("input")}>
          {selectedVendor.value.Discount ? (
            // Vendors with Discount (prompt for List Price)
            <>
              <label>List Price</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className={pre("csr-input")}
                  type="number"
                  value={listPrice}
                  onChange={handleListPriceChange}
                  placeholder="Enter list price"
                  ref={listPriceInputRef}
                />
                {calculatedCost && !isNaN(calculatedCost) && (
                  <span
                    className={pre("calculated")}
                    onClick={() => handleCopy(calculatedCost.toFixed(2))}
                  >
                    <span>Cost:&nbsp;</span>${calculatedCost.toFixed(2)}
                  </span>
                )}
              </div>
            </>
          ) : (
            // Vendors without Discount (prompt for Cost)
            <>
              <label>Cost:</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  className={pre("csr-input")}
                  type="number"
                  value={cost}
                  onChange={handleCostChange}
                  placeholder="Enter cost"
                  ref={costInputRef}
                />
                {cost && !isNaN(cost) && (
                  <span
                    className={`${pre("calculated")} ${
                      overrideListPrice ? "overridePrice" : ""
                    }`}
                    onClick={() => setModalOpen(true)} // Open modal to override List Price
                  >
                    <span>List Price:&nbsp;</span>$
                    {formatNumber(overrideListPrice || calculatedListPrice)}
                  </span>
                )}
              </div>
            </>
          )}

          {selectedVendor.value.MinOrder && (
            <div className={pre("minOrder")}>
              MINIMUM ORDER: {selectedVendor.value.MinOrder}
            </div>
          )}

          {prices && <DisplayPrices prices={prices} tier={tier} />}
          <div className={pre("checkbox")}>
            <label>
              <input
                type="checkbox"
                checked={showOldPricing}
                onChange={(e) => setShowOldPricing(e.target.checked)}
              />{" "}
              Show old pricing
            </label>
          </div>

          <Drawer isOpen={showDetails} setIsOpen={handleToggleDetails}>
            <div className={pre("pricing-details")}>
              <h3>Case Parts Pricing Details</h3>
              <div>
                <label>Manufacturer:</label>
                <p>{selectedVendor.value.Vendor}</p>
              </div>
              {selectedVendor.value.Discount && (
                <div>
                  <label>Discount:</label>
                  <p>{(selectedVendor.value.Discount * 100).toFixed(2)}%</p>
                </div>
              )}
              {!selectedVendor.value.Discount && (
                <>
                  {selectedVendor.value.AvgDiscount && (
                    <div>
                      <label>Average Discount:</label>
                      <p>
                        &asmp;
                        {(selectedVendor.value.AvgDiscount * 100).toFixed(2)}%
                      </p>
                    </div>
                  )}
                  {actualAvgDiscount !== selectedVendor.value.AvgDiscount && (
                    <div className="overridePrice">
                      <label>Actual Discount:</label>
                      <p>{(actualAvgDiscount * 100).toFixed(2)}%</p>
                    </div>
                  )}
                </>
              )}
              <div>
                <label>End User Markup:</label>
                <p>{endUserMarkup ? endUserMarkup.toFixed(2) : "N/A"}</p>
              </div>
              <div>
                <label>Dealer Markup:</label>
                <p>{selectedVendor.value.DealerMarkup.toFixed(2)}</p>
              </div>
              <div>
                <label>Wholesaler Markup:</label>
                <p>{selectedVendor.value.WholesalerMarkup.toFixed(2)}</p>
              </div>
            </div>
          </Drawer>

          {/* ListPriceModal for overriding List Price */}
          <ListPriceModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            onSubmit={handleListPriceSubmit}
            initialPrice={overrideListPrice}
          />
        </div>
      )}
    </div>
  );
};

export default SelectManufacturer;
