import React, { useState } from "react";
import vendorsData from "../data/vendors";
import "../styles/ReviewMarkups.css";
import useMarkups from "../components/useMarkups";
import markupData from "../data/vendorMarkups";
import ReturnPrevious from "../components/ReturnPrevious";

const pre = (c) => "review-markups__" + c;

const VendorMarkups2 = () => {
  const [sortByDiscount, setSortByDiscount] = useState(true);
  const { calcEndUserMarkup, calcDealerMarkup, calcWholesalerMarkup } =
    useMarkups(markupData);

  const vendors = sortByDiscount
    ? [...vendorsData].sort((a, b) => {
        const discountA = a.Discount ?? a.AvgDiscount ?? 99;
        const discountB = b.Discount ?? b.AvgDiscount ?? 99;
        return discountA === discountB
          ? a.Vendor.localeCompare(b.Vendor)
          : discountA - discountB;
      })
    : vendorsData;

  const renderTierMarkup = (oldMarkup, newMarkup, variance) => (
    <div
      style={{ fontSize: "12px", display: "inline-block", marginRight: "20px" }}
    >
      <div>{oldMarkup.toFixed(2)}</div>
      <div>{newMarkup.toFixed(2)}</div>
      <div style={{ color: variance > 0 ? "green" : "red" }}>
        {variance.toFixed(2)}
      </div>
    </div>
  );

  const renderVendorRow = (vendor) => {
    const discount = vendor.Discount ?? vendor.AvgDiscount ?? 0;
    const standardEndUserMarkup = calcEndUserMarkup(discount);
    const standardDealerMarkup = calcDealerMarkup(discount);
    const standardWholesalerMarkup = calcWholesalerMarkup(discount);

    const endUserVariance = standardEndUserMarkup - vendor.EnduserMarkup;
    const dealerVariance = standardDealerMarkup - vendor.DealerMarkup;
    const wholesalerVariance =
      standardWholesalerMarkup - vendor.WholesalerMarkup;

    return (
      <div className={pre("row")} key={vendor.Vendor}>
        <div>{vendor.Vendor}</div>
        <div>{(discount * 100).toFixed(2)}%</div>
        <div>
          {renderTierMarkup(
            vendor.EnduserMarkup,
            standardEndUserMarkup,
            endUserVariance
          )}
        </div>
        <div>
          {renderTierMarkup(
            vendor.DealerMarkup,
            standardDealerMarkup,
            dealerVariance
          )}
        </div>
        <div>
          {renderTierMarkup(
            vendor.WholesalerMarkup,
            standardWholesalerMarkup,
            wholesalerVariance
          )}
        </div>
      </div>
    );
  };

  const generateCSVData = () => {
    let csvContent = `,,,,EndUser,,,,Dealer,,,,Wholesaler,\n`;
    csvContent += `Case Parts Vendor,Discount,,Old,New,Δ,,Old,New,Δ,,Old,New,Δ\n`;

    vendors.forEach((vendor) => {
      const discount = vendor.Discount ?? vendor.AvgDiscount;
      const endUserVariance =
        calcEndUserMarkup(discount) - vendor.EnduserMarkup;
      const dealerVariance = calcDealerMarkup(discount) - vendor.DealerMarkup;
      const wholesalerVariance =
        calcWholesalerMarkup(discount) - vendor.WholesalerMarkup;

      csvContent += `${vendor.Vendor},${(discount * 100).toFixed(2)}%,,`;
      csvContent += `${vendor.EnduserMarkup.toFixed(2)},${calcEndUserMarkup(
        discount
      ).toFixed(2)},${endUserVariance.toFixed(2)},,`;
      csvContent += `${vendor.DealerMarkup.toFixed(2)},${calcDealerMarkup(
        discount
      ).toFixed(2)},${dealerVariance.toFixed(2)},,`;
      csvContent += `${vendor.WholesalerMarkup.toFixed(
        2
      )},${calcWholesalerMarkup(discount).toFixed(
        2
      )},${wholesalerVariance.toFixed(2)}\n`;
    });

    return csvContent;
  };

  const saveCSV = () => {
    try {
      const csvData = generateCSVData();
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");

      if (a.download !== undefined) {
        a.href = url;
        a.download = "markups.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        alert("Your browser does not support file downloads.");
      }

      window.URL.revokeObjectURL(url); // Clean up after download
    } catch (error) {
      console.error("Error saving CSV:", error);
      alert("Failed to save the CSV file. Please try again.");
    }
  };

  return (
    <div className={pre("container")}>
      <div className="alert">
        <strong>ATTENTION:</strong>&ensp;Case Parts internal use only!
      </div>
      <h1>
        Review Vendor Markups <ReturnPrevious url="/" />
      </h1>
      <button onClick={saveCSV}>Save CSV</button>

      {/* Table header */}
      <div className={pre("header")}>
        <div>Vendor</div>
        <div>Discount</div>
        <div>EndUser</div>
        <div>Dealer</div>
        <div>Wholesaler</div>
      </div>

      {/* Table rows */}
      {vendors.map(renderVendorRow)}
    </div>
  );
};

export default VendorMarkups2;
